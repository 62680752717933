import React from "react";
import "./Opciones.css";
import { Link } from "react-router-dom";
import multipagos from "../img/boton-multipagos.png";
import ekampus from "../img/boton-ekampus.png";
import BAC from "../img/boton-BAC.png";

const Opciones = () => {
  return (
    <div className="Opciones">
      <div className="Opciones-titulo">Selecciona una opción:</div>
      <div className="Opciones-flex">
        <div className="Opcion">
          <Link to="/login">
            <img src={multipagos} alt="" />
          </Link>
          <div> </div>
        </div>
        <div className="Opcion">
          <a href="https://aspirantes.cecytejalisco.edu.mx/RegistroAspirantesJalisco/?modulo=login">
            <img src={ekampus} alt="" title="prueba" />
          </a>
          <div> </div>
        </div>
        <div className="Opcion">
          <a href="http://bac.cecytejalisco.edu.mx/">
            <img src={BAC} alt="" />
          </a>
          <div> </div>
        </div>
      </div>
    </div>
  );
};

export default Opciones;
