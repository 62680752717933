import CryptoJS from "crypto-js";

const ReferenciaOnline = (referencia, importe)=>{
    let formData = new FormData();
    const datos = referencia.concat(importe)
    formData.append("importe", datos);
    formData.append(
      "cod",
      CryptoJS.SHA256("pcecytej-".concat(datos, "-pcecytej"))
    );
    
    const fun = async () => {
      const res = await fetch(
        "https://escolar.cecytejalisco.edu.mx/SistemaEscolarJalisco/funcion/wsreferencias.php",
        { body: formData, method: "POST" }
      );
      const resp = await res.json();
      return resp[0].importe
    };
    
    return fun();
}

export default ReferenciaOnline