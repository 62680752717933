const DigitoverBanorte = ({ monto, referencia }) => {
  let digito = 0;

  let cantidad = 0;

  // const calcularCantidad = () => {
  //   console.log(monto);
  //   console.log(referencia);

  //   let multiplicador = 0;

  //   for (let i = 0; i < monto.length; i++) {
  //     switch (multiplicador) {
  //       case 0: {
  //         cantidad = cantidad + monto[monto.length - i - 1] * 1;
  //         console.log(monto.length - i - 1, monto[monto.length - i - 1], 1);
  //         break;
  //       }
  //       case 1: {
  //         cantidad = cantidad + monto[monto.length - i - 1] * 7;
  //         console.log(monto.length - i - 1, monto[monto.length - i - 1], 7);
  //         break;
  //       }
  //       case 2: {
  //         cantidad = cantidad + monto[monto.length - i - 1] * 3;
  //         console.log(monto.length - i - 1, monto[monto.length - i - 1], 3);
  //         break;
  //       }
  //     }
  //     multiplicador++;
  //     if (multiplicador === 3) {
  //       multiplicador = 0;
  //     }
  //   }
  //   console.log("suma", cantidad);
  //   console.log("verificador monto ", cantidad % 10);
  //   cantidad = cantidad % 10;
  // };

  // calcularCantidad();

  // referencia = referencia.concat(cantidad.toString());
  // const digitoaux = referencia.slice(referencia.length - 13, referencia.length);

  // let j = 0;
  // for (let i = 1; i <= digitoaux.length; i++) {
  //   j++;
  //   if (j === 5) {
  //     digito = digito + digitoaux[digitoaux.length - i] * 23;
  //     j = 0;
  //     continue;
  //   }
  //   if (j === 4) {
  //     digito = digito + digitoaux[digitoaux.length - i] * 19;
  //     continue;
  //   }
  //   if (j === 3) {
  //     digito = digito + digitoaux[digitoaux.length - i] * 17;
  //     continue;
  //   }
  //   if (j === 2) {
  //     digito = digito + digitoaux[digitoaux.length - i] * 13;
  //     continue;
  //   }
  //   digito = digito + digitoaux[digitoaux.length - i] * 11;
  // }

  // digito = ((digito % 97) + 1).toString();
  // if (digito.length === 1) {
  //   digito = "0".concat(digito);
  // }
  // referencia = referencia.concat(digito);
  const priceMultiplier = [1, 7, 3];
  const refMultiplier = [11, 13, 17, 19, 23];

  let multiplier = 0;

  let priceDigit = 0;
  let refDigit = 0;

  for (let i = 0; i < monto.length; i++) {
    priceDigit =
      priceDigit +
      Number(monto[monto.length - i - 1]) * priceMultiplier[multiplier];
    multiplier = multiplier + 1;
    if (multiplier === 3) multiplier = 0;
  }

  priceDigit = priceDigit % 10;

  referencia = referencia + priceDigit.toString();

  multiplier = 0;

  for (let i = 0; i < referencia.length; i++) {
    refDigit =
      refDigit +
      Number(referencia[referencia.length - i - 1]) * refMultiplier[multiplier];
    multiplier = multiplier + 1;
    if (multiplier === 5) multiplier = 0;
  }

  refDigit = ((refDigit % 97) + 1).toString();

  if (refDigit.length === 1) refDigit = "0".concat(refDigit);
  referencia = referencia + refDigit;

  return referencia;
};

export default DigitoverBanorte;
